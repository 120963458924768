/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from 'react';
import logo from '../assets/images/logo.png';
import Button from '../elements/Button';

export default function BrandIcon() {
  return (
    <Button
      className=""
      type="link"
      href="/"
    >
      <img src={logo} alt="Company Logo" className="w-[300px]" />
    </Button>
  );
}
