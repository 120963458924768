/* eslint-disable import/extensions */

// Services Img Imports
import Web from '../assets/images/Services/Web.png';
import Mobile from '../assets/images/Services/Mobile.png';
import UIUX from '../assets/images/Services/Design.png';

// Portfolio Img Imports
import Recruiting from '../assets/images/Portfolio/Recruiting.png';
import Stream from '../assets/images/Portfolio/Stream.png';
import Freelance from '../assets/images/Portfolio/Freelance.png';
import Aura from '../assets/images/Portfolio/Aura.png';
import Surtido from '../assets/images/Portfolio/Surtido.png';
import ManagementApp from '../assets/images/Portfolio/ManagementApp.png';

// Advantages
import Communicative from '../assets/images/Advantages/Communicative.png';
import Collaborative from '../assets/images/Advantages/Collaborative.png';
import Management from '../assets/images/Advantages/Management.png';
import Favorite from '../assets/images/Advantages/Favorite.png';

// Testimonials
import Sasha from '../assets/images/Testimonials/Sasha.jpg';
import Reiner from '../assets/images/Testimonials/Reiner.jpg';
import SteveShene from '../assets/images/Testimonials/Kruger.jpg';

// TeamMembers
import CEO from '../assets/images/TeamMembers/CEO.jpg';
import HRD from '../assets/images/TeamMembers/HRD.jpg';
import Finance from '../assets/images/TeamMembers/Finance.jpg';
import ProjectManager from '../assets/images/TeamMembers/Project-manager.jpg';
import Frontend1 from '../assets/images/TeamMembers/Frontend1.jpg';
import Frontend2 from '../assets/images/TeamMembers/Frontend2.jpg';
import Backend1 from '../assets/images/TeamMembers/Backend1.jpg';
import Backend2 from '../assets/images/TeamMembers/Backend2.jpg';

export const Services = [
  {
    title: 'Web Development',
    imageUrl: Web,
    animation: 'left',
  },
  {
    title: 'Mobile Development',
    imageUrl: Mobile,
    animation: 'up',
  },
  {
    title: 'UI/UX Design',
    imageUrl: UIUX,
    animation: 'right',
  },
];

export const Portfolios = [
  {
    id: 'asd1293uasdads1',
    title: 'Recruiting App',
    imageUrl: Recruiting,
    type: 'Mobile Apps',
    productTitle: 'Revolutionize Your Hiring Process',
    details: 'Imperative Soft recruitment app is a game-changer for both job seekers and employers. It streamlines the hiring process with advanced job matching, efficient candidate management, and a user-friendly interface. Real-time analytics provide valuable insights to optimize your hiring strategy. Elevate your recruitment game today! Contact us to learn more.',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15164950-Recruiting-app',
  },
  {
    id: 'asd1293uhjkhkjh2',
    title: 'Stream Plus',
    imageUrl: Stream,
    type: 'Mobile Apps',
    productTitle: 'Elevate Your Entertainment Business with Our Cutting-Edge Streaming App',
    details: 'Revolutionize your digital entertainment platform with our powerful and user-friendly streaming app. Our solution offers a seamless viewing experience, driving user engagement and boosting revenue. With features like high-quality streaming, personalized recommendations, offline viewing, multi-screen streaming, a robust content management system, and advanced security measures, we help you take your entertainment business to the next level. Contact us today to learn more.',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15276430-Stream',
  },
  {
    id: 'asd1293uvbvcbbd3',
    title: 'Freelance',
    imageUrl: Freelance,
    type: 'Mobile Apps',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15223131-Freelance-Mobile-App-Concept',
  },
  {
    id: 'asd1293ufgdfgs4',
    title: 'Aura',
    imageUrl: Aura,
    type: 'Website',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15176338-Aura-Website-Main-Page',
  },
  {
    id: 'asd1293ulskmnb5',
    title: 'Surtido Rico',
    imageUrl: Surtido,
    type: 'Website',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15173118-Surtido-Rico',
  },
  {
    id: 'asd1293ulkmnbj6',
    title: 'Courses Management',
    imageUrl: ManagementApp,
    type: 'Website',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15197890-Courses-Management-and-Promoting-Web-App',
  },
];

export const Advantages = [
  [{
    title: 'Communicative',
    description: 'We communicate our project ideas and progress to make it clear.',
    imageUrl: Communicative,
  },
  {
    title: 'Management',
    description: 'We manage our project properly to make our project done well.',
    imageUrl: Management,
  }],
  [{
    title: 'Collaborative​',
    description: 'Our team are very collaborative to make our project done well.',
    imageUrl: Collaborative,
  },
  {
    title: 'Favorite',
    description: "We've did so many project and all of our client love it.",
    imageUrl: Favorite,
  }],
];
export const Testimonials = [
  {
    id: 1,
    name: 'Nedu Achonu',
    company: 'Owner, Achonu',
    testimoni: 'Thanks for Imperative Soft, you guys are the best! Keep up the great work!',
    imageUrl: Sasha,
  },
  {
    id: 2,
    name: 'Steve Shen',
    company: 'Team Leader, EXP Realty',
    testimoni: 'I just wanted to let you know that it’s been great working with Imperative Soft.',
    imageUrl: SteveShene,
  },
  {
    id: 3,
    name: 'Alex Saal',
    company: 'Founder & CEO, Solid Ecom Automation',
    testimoni: 'Imperative Soft is so great. Thank you so much for a job well done.',
    imageUrl: Reiner,
  },
];

export const TeamMembers = [
  {
    name: 'Jewel Rana',
    position: 'Full Stack Developer',
    imageUrl: CEO,
  },
  {
    name: 'Tanvir Ahmed Khan',
    position: 'Asst Full Stack Developer',
    imageUrl: HRD,
  },
  {
    name: 'Khondokar Shahir Saadat',
    position: 'Flutter Developer',
    imageUrl: ProjectManager,
  },
  {
    name: 'Saurav Anwar',
    position: 'Asst Flutter Developer',
    imageUrl: Finance,
  },
  {
    name: 'Nayem Gawhar',
    position: 'Technical Lead',
    imageUrl: Frontend1,
  },
  {
    name: 'Mahin Islam',
    position: 'Operation Lead',
    imageUrl: Frontend2,
  },
  {
    name: 'Khaledur Rahman',
    position: 'Back-end Developer',
    imageUrl: Backend1,
  },
  {
    name: 'Shoma Chowdhury',
    position: 'UI UX Designer',
    imageUrl: Backend2,
  },
];
